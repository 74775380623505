const links = {
  core: 'https://github.com/TileDB-Inc/TileDB',
  vcfLib: 'https://github.com/TileDB-Inc/TileDB-VCF',
  coreStarGazers: 'https://github.com/TileDB-Inc/TileDB/stargazers',
  allRepos: 'https://github.com/TileDB-Inc',
  docs: 'https://docs.tiledb.com/main/',
  apis: 'https://docs.tiledb.com/main/#high-level-language-apis',
  blog: '/blog',
  blogPreview: '/preview/blog',
  dataframes: 'https://docs.tiledb.com/main/basic-concepts/data-model#dataframes-can-be-modeled-as-dense-or-sparse-arrays',
  geospatial: 'https://docs.tiledb.com/main/integrations-and-extensions/geospatial',
  cloudDocs: 'https://docs.tiledb.com/cloud/',
  genomics: 'https://docs.tiledb.com/main/integrations-and-extensions/population-genomics',
  embedded: '/products/tiledb-embedded',
  cloud: '/products/tiledb-cloud',
  cloudSaas: '/products/tiledb-cloud/saas',
  openSource: {
    home: '/open-source',
    arrayStorage: '/open-source/array-storage',
    lifeSciences: '/open-source/life-sciences',
    geospatial: '/open-source/geospatial',
    machineLearning: '/open-source/machine-learning',
    distributedCompute: '/open-source/distributed-compute',
  },
  cloudEnterprise: '/products/enterprise',
  console: {
    login: 'https://cloud.tiledb.com/auth/login',
    signup: 'https://cloud.tiledb.com/auth/signup',
    explore: 'https://cloud.tiledb.com/arrays/public',
    user: {
      settings: 'https://cloud.tiledb.com/settings',
      billing: 'https://cloud.tiledb.com/settings/billing',
    },
  },
  docPages: {
    universalDataFormat: 'https://docs.tiledb.com/main/concepts/data-format',
    immutableFragments: 'https://docs.tiledb.com/main/concepts/data-format#immutable-fragments',
    integrations: 'https://docs.tiledb.com/main/#code-and-apis',
    backends: 'https://docs.tiledb.com/main/how-to/backends',
    geospatial: 'https://docs.tiledb.com/main/integrations-and-extensions/geospatial',
    lidar: 'https://docs.tiledb.com/cloud/tutorials/use-cases/lidar/lidar-quickstart',
    useCases: 'https://docs.tiledb.com/main/',
    main: 'https://docs.tiledb.com/main/',
  },
  linkedin: 'https://www.linkedin.com/company/tiledb-inc',
  x: 'https://x.com/tiledb',
  medium: 'https://medium.com/tiledb',
  forum: 'https://forum.tiledb.com',
  videos: 'https://www.youtube.com/c/TileDBInc',
  events: 'https://events.tiledb.com/',
  careers: 'https://ats.rippling.com/tiledb-careers/jobs',
  updates: '/updates',
  privacyPolicy: '/privacy-policy',
  requestDemo: '/discovery',
  terms: '/terms',
  contact: '/contact',
  about: '/about',
  slack: 'https://tiledb-community.slack.com/',
  joinSlack: 'https://join.slack.com/t/tiledb-community/shared_invite/zt-ndq1ipwl-QcithaWG6j1BImtuQGSpag',
  docsPricing: 'https://docs.tiledb.com/cloud/concepts/pricing',
  pricing: '/pricing',
  support: 'https://support.tiledb.com/hc/en-us',
  contactSales: '/contact-sales',
  dataTypes: {
    lidar: '/data-types/lidar',
    ais: '/data-types/ais',
    vcf: '/data-types/vcf',
    singleCell: '/data-types/single-cell',
    weather: '/data-types/weather',
    sar: '/data-types/sar',
    location: '/data-types/location',
    hyperspectral: '/data-types/hyperspectral',
    opticalImaging: '/data-types/biomedical-imaging',
    dataframes: '/data-types/dataframes',
    financialData: '/data-types/financial',
    timeSeries: '/data-types/time-series',
    video: '/data-types/video',
  },
  industries: {
    maritime: '/industries/maritime',
    healthcare: '/industries/healthcare',
    telecom: '/industries/telecommunications',
    defense: '/industries/defense',
    earthObservation: '/industries/earth-observation',
    oilAndGas: '/industries/oil-and-gas',
    financial: '/industries/financial-services',
    reinsurance: '/industries/reinsurance',
  },
  github: {
    tiledbPy: 'https://github.com/TileDB-Inc/TileDB-Py',
    tiledbR: 'https://github.com/TileDB-Inc/TileDB-R',
    tiledbJava: 'https://github.com/TileDB-Inc/TileDB-Java',
    tiledbCLI: 'https://github.com/TileDB-Inc/TileDB-CLI',
    tiledbCSharp: 'https://github.com/TileDB-Inc/TileDB-CSharp',
    tiledbCloudJS: 'https://github.com/TileDB-Inc/TileDB-Cloud-JS',
    tiledbGO: 'https://github.com/TileDB-Inc/TileDB-Go',
    tiledbVCF: 'https://github.com/TileDB-Inc/TileDB-VCF',
    tiledbSOMA: 'https://github.com/single-cell-data/TileDB-SOMA',
    tiledbSOMASpec: 'https://github.com/single-cell-data/SOMA',
    tiledbViz: 'https://github.com/TileDB-Inc/TileDB-Viz',
    tiledbPyBabylonJS: 'https://github.com/TileDB-Inc/TileDB-PyBabylonJS',
    tiledbCFPy: 'https://github.com/TileDB-Inc/TileDB-CF-Py',
    tiledbSegy: 'https://github.com/TileDB-Inc/TileDB-Segy',
    tiledbML: 'https://github.com/TileDB-Inc/TileDB-ML',
    tiledbSpark: 'https://github.com/TileDB-Inc/TileDB-Spark',
    tiledbTrino: 'https://github.com/TileDB-Inc/TileDB-Trino',
    tiledbPresto: 'https://github.com/TileDB-Inc/TileDB-Presto',
    tiledbJDBC: 'https://github.com/TileDB-Inc/TileDB-Cloud-JDBC',
    tiledbTableauConnector: 'https://github.com/TileDB-Inc/TileDB-Tableau-Connector',
    tiledbMariaDB: 'https://github.com/TileDB-Inc/TileDB-MariaDB',
    tiledbBioimaging: 'https://github.com/TileDB-Inc/TileDB-BioImaging',
    tiledbPythonDBAPI: 'https://github.com/TileDB-Inc/TileDB-Cloud-PythonDB',
    tiledbCPlusPlus: 'https://github.com/TileDB-Inc/TileDB',
    tiledbC: 'https://github.com/TileDB-Inc/TileDB',
  },
  case_studies: {
    phenomic_ai: '/case-studies/phenomic-ai',
    rady_children: '/case-studies/rady-children',
    quest: '/case-studies/quest-diagnostics',
    earthscope: '/case-studies/earthscope',
    dexter: 'https://dexterenergy.ai/news/leveraging-500tb-of-weather-forecasts/',
    cellarity: '/case-studies/cellarity',
  },
  academy: {
    root: 'https://cloud.tiledb.com/academy',
    whatIsTileDB: 'https://cloud.tiledb.com/academy/what-is-tiledb/',
    getStarted: 'https://cloud.tiledb.com/academy/get-started',
    catalog: 'https://cloud.tiledb.com/academy/catalog',
    groups: 'https://cloud.tiledb.com/academy/catalog/groups',
    search: 'https://cloud.tiledb.com/academy/catalog/search',
    organizations: 'https://cloud.tiledb.com/academy/collaborate/organizations',
    share: 'https://cloud.tiledb.com/academy/collaborate/access-control/share-assets',
    comply: 'https://cloud.tiledb.com/academy/collaborate/introduction',
    collaborate: 'https://cloud.tiledb.com/academy/collaborate',
    logging: 'https://cloud.tiledb.com/academy/collaborate/logging',
    preview: 'https://cloud.tiledb.com/academy/analyze/preview',
    dashboards: 'https://cloud.tiledb.com/academy/analyze/dashboards/',
    notebooks: 'https://cloud.tiledb.com/academy/analyze/notebooks/',
    scale: 'https://cloud.tiledb.com/academy/scale/',
    analyze: 'https://cloud.tiledb.com/academy/analyze',
    structure: 'https://cloud.tiledb.com/academy/structure',
    glossary: 'https://cloud.tiledb.com/academy/glossary',
    populationGenomics: 'https://cloud.tiledb.com/academy/structure/life-sciences/population-genomics',
    singleCell: 'https://cloud.tiledb.com/academy/structure/life-sciences/single-cell',
    biomedicalImaging: 'https://cloud.tiledb.com/academy/structure/life-sciences/biomedical-imaging',
    arrays: 'https://cloud.tiledb.com/academy/structure/arrays',
    tables: 'https://cloud.tiledb.com/academy/structure/tables',
    AIML: 'https://cloud.tiledb.com/academy/structure/ai-ml/vector-search',
  },
};

export default links;
